import { NgModule, inject } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { BlankComponent } from './layouts/blank/blank.component';
import { AuthGuard } from './auth.guard';
import { FullComponent } from './layouts/full/full.component';
// import { AuthGuard } from './auth.guard';

const routes: Routes = [
  {
    path: '',
    component: FullComponent,
    canActivate: [() => inject(AuthGuard).canActivate()],
    children: [
      {
        path: '',
        redirectTo: '/grievances',
        pathMatch: 'full',
      },
      {
        path: 'grievances',
        loadChildren: () =>
          import('./pages/grievances/grievance.module').then(
            (m) => m.GrievanceModule
          ),
      },
    ],
  },
  {
    path: '',
    component: BlankComponent,
    children: [
      {
        path: 'authentication',
        loadChildren: () =>
          import('./pages/authentication/authentication.module').then(
            (m) => m.AuthenticationModule
          ),
      },
    ],
  },
  {
    path: '**',
    redirectTo: 'authentication/error',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
