import {
  Component,
  Output,
  EventEmitter,
  Input,
  ViewEncapsulation,
} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {navItems} from '../sidebar/sidebar-data';
import {TablerIconsModule} from 'angular-tabler-icons';
import {Router, RouterModule} from '@angular/router';
import {CommonModule, NgForOf} from '@angular/common';
import {FormsModule} from '@angular/forms';
import {NgScrollbarModule} from 'ngx-scrollbar';
import {MaterialModule} from '../../../../material.module';
import {CoreService} from '../../../../services/core.service';
import {AuthService} from "../../../../services/auth.service";

@Component({
  selector: 'app-header',
  standalone: true,
  imports: [
    RouterModule,
    CommonModule,
    NgScrollbarModule,
    TablerIconsModule,
    MaterialModule,
  ],
  templateUrl: './header.component.html',
  encapsulation: ViewEncapsulation.None,
})
export class HeaderComponent {
  @Input() showToggle = true;
  @Input() toggleChecked = false;
  @Output() toggleMobileNav = new EventEmitter<void>();
  @Output() toggleMobileFilterNav = new EventEmitter<void>();
  @Output() toggleCollapsed = new EventEmitter<void>();

  showFiller = false;

  constructor(private vsidenav: CoreService,
              public authService: AuthService,
              private router: Router,
              public dialog: MatDialog) {
  }

  logout() {
    this.authService.logout();
    this.router.navigate(['/login']);
  }
}
