<mat-sidenav-container
  class="mainWrapper"
  autosize
  autoFocus
  [ngClass]="{
    'sidebarNav-mini':
      options.sidenavCollapsed && options.navPos !== 'top' && !resView,
    'sidebarNav-horizontal': options.horizontal,
    cardBorder: options.cardBorder,
    orange_theme: options.activeTheme == 'orange_theme',
    blue_theme: options.activeTheme == 'blue_theme',
    aqua_theme: options.activeTheme == 'aqua_theme',
    purple_theme: options.activeTheme == 'purple_theme',
    green_theme: options.activeTheme == 'green_theme',
    cyan_theme: options.activeTheme == 'cyan_theme'
  }"
  [dir]="options.dir!"
>
  <!-- ============================================================== -->
  <!-- Vertical Sidebar -->
  <!-- ============================================================== -->
  @if (!options.horizontal) {
  <mat-sidenav
    #leftsidenav
    [mode]="isOver ? 'over' : 'side'"
    [opened]="
      options.navPos === 'side' && options.sidenavOpened && !isOver && !resView
    "
    (openedChange)="onSidenavOpenedChange($event)"
    (closedStart)="onSidenavClosedStart()"
    class="sidebarNav"
  >
    <div class="flex-layout">
      <app-sidebar [showToggle]="isOver"></app-sidebar>
      <ng-scrollbar class="position-relative" style="height: 100%">
        <mat-nav-list class="sidebar-list">
          @for (item of navItems; track item) {
          <app-nav-item [item]="item"></app-nav-item>
          }
        </mat-nav-list>
      </ng-scrollbar>
      <div class="p-24 m-t-auto profile-bar">
        <div class="bg-light-accent d-flex align-items-center rounded p-16">
          <i-tabler
            name="user"
            class="big fill-red stroke-blue thin"
          ></i-tabler>
          <div class="m-l-16">
            <h5 class="mat-subtitle-2 f-w-600">
              {{ authService.currentOfficialDetails?.name }}
            </h5>
            <span class="f-s-12">{{
              authService.currentOfficialDetails?.phoneNumber
            }}</span>
          </div>
          <div class="m-l-auto">
            <a
              mat-icon-button
              (click)="logout()"
              class="d-flex justify-content-center"
            >
              <i-tabler
                name="power"
                class="text-primary icon-18 d-flex"
              ></i-tabler>
            </a>
          </div>
        </div>
      </div>
    </div>
  </mat-sidenav>
  }

  <!-- ============================================================== -->
  <!-- Main Content -->
  <!-- ============================================================== -->
  <mat-sidenav-content class="contentWrapper" #content>
    <!-- ============================================================== -->
    <!-- VerticalHeader -->
    <!-- ============================================================== -->
    <app-header
      [showToggle]="!isOver"
      (toggleCollapsed)="toggleCollapsed()"
      (toggleMobileFilterNav)="filterNavRight.toggle()"
    ></app-header>

    <main
      class="pageWrapper"
      [ngClass]="{
        maxWidth: options.boxed
      }"
    >
      <!-- ============================================================== -->
      <!-- Outlet -->
      <!-- ============================================================== -->
      <router-outlet></router-outlet>
    </main>
  </mat-sidenav-content>
</mat-sidenav-container>

<!-- ------------------------------------------------------------------
    Mobile Apps Sidebar
    ------------------------------------------------------------------ -->
<mat-drawer #filterNavRight mode="over" position="end" class="filter-sidebar">
  <div>
    <div class="d-flex justify-content-between align-items-center">
      <div class="branding">
        @if (options.theme === 'light') {
        <a href="/">
          <img
            src="./assets/images/logos/dark-logo.svg"
            class="align-middle m-2"
            alt="logo"
          />
        </a>
        } @else {
        <a href="/">
          <img
            src="./assets/images/logos/light-logo.svg"
            class="align-middle m-2"
            alt="logo"
          />
        </a>
        }
      </div>
      <button
        mat-icon-button
        (click)="filterNavRight.toggle()"
        class="d-flex justify-content-center"
      >
        <i-tabler name="x" class="icon-18 d-flex"></i-tabler>
      </button>
    </div>

    <mat-accordion>
      <mat-expansion-panel class="shadow-none">
        <mat-expansion-panel-header>
          <mat-panel-title class="f-s-16 f-w-500"> Apps</mat-panel-title>
        </mat-expansion-panel-header>
        <div>
          <div class="row">
            @for (appdd of apps; track appdd.img) {
            <div class="col-12 text-hover-primary">
              <a
                [routerLink]="[appdd.link]"
                class="d-flex align-items-center text-decoration-none m-b-24 gap-16"
              >
                <button
                  mat-mini-fab
                  class="text-primary bg-light-primary shadow-none rounded"
                >
                  <img [src]="appdd.img" width="20" />
                </button>

                <div>
                  <h5
                    class="f-s-14 f-w-600 m-0 textprimary mat-body-1 hover-text"
                  >
                    {{ appdd.title }}
                  </h5>
                  <span class="mat-body-1 f-s-12">{{ appdd.subtitle }}</span>
                </div>
              </a>
            </div>
            }
          </div>
          <h4 class="f-s-18 f-w-600 mat-subtitle-1 m-b-16">Quick Links</h4>
          @for (quicklink of quicklinks; track quicklink.title) {
          <div class="text-hover-primary">
            <a
              [routerLink]="['quicklink.link']"
              class="hover-text text-decoration-none mat-body-1 f-w-600 d-block p-y-8"
              >{{ quicklink.title }}</a
            >
          </div>
          }
        </div>
      </mat-expansion-panel>
    </mat-accordion>

    <div class="p-x-24">
      <a
        [routerLink]="['/apps/calendar']"
        class="d-flex align-items-center text-decoration-none mat-body-1 p-y-16 gap-8"
      >
        <span class="f-s-15 f-w-500 m-l-8">Calendar</span>
      </a>
      <a
        [routerLink]="['/apps/chat']"
        class="d-flex align-items-center text-decoration-none mat-body-1 p-y-16"
      >
        <span class="f-s-15 f-w-500 m-l-8">Chat</span>
      </a>
      <a
        [routerLink]="['/apps/email/inbox']"
        class="d-flex align-items-center text-decoration-none mat-body-1 p-y-16 gap-8"
      >
        <span class="f-s-15 f-w-500 m-l-8">Email</span>
      </a>
    </div>
  </div>
</mat-drawer>
