import { Injectable } from '@angular/core';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router,
} from '@angular/router';
import { map, Observable, of } from 'rxjs';
import { AuthService } from './services/auth.service';
import { catchError, switchMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {
  constructor(private router: Router, private authService: AuthService) {}

  canActivate(): Observable<boolean> {
    const loginPage = `/authentication/login`;
    const userDetails = new Observable<boolean>();
    if (this.authService.currentOfficialDetails) {
      this.router.navigate([loginPage]);
      return of(true);
    }

    if (this.authService.hasRefreshToken()) {
      return this.authService.refreshToken().pipe(
        switchMap((success) => {
          if (success) {
            return this.authService.getDetails().pipe(
              map((details) => {
                if (details) return true;
                this.router.navigate([loginPage]);
                return false;
              }),
              catchError(() => {
                this.router.navigate([loginPage]);
                return of(false);
              })
            );
          } else {
            this.router.navigate([loginPage]);
            return of(false);
          }
        }),
        catchError(() => {
          this.router.navigate([loginPage]);
          return of(false);
        })
      );
    } else {
      this.router.navigate([loginPage]);
      return of(false);
    }
  }
}
