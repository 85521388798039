<mat-toolbar class="topbar">
  <!-- --------------------------------------------------------------- -->
  <!-- Desktop Menu -->
  @if (showToggle) {
    <button
      mat-icon-button
      (click)="toggleCollapsed.emit()"
      class="d-flex justify-content-center"
    >
      <i-tabler name="menu-2" class="icon-20 d-flex"></i-tabler>
    </button>
  }

  <!-- Mobile Menu -->
  @if (!showToggle) {
    <button
      mat-icon-button
      (click)="toggleMobileNav.emit()"
      class="d-flex justify-content-center"
    >
      <i-tabler name="menu-2" class="icon-20 d-flex"></i-tabler>
    </button>
  }
  <!-- --------------------------------------------------------------- -->

  <span class="flex-1-auto"></span>

  <!-- Mobile Menu -->
  <button
    mat-icon-button
    (click)="toggleMobileFilterNav.emit()"
    class="d-flex d-lg-none justify-content-center"
  >
    <i-tabler name="grid-dots" class="icon-20 d-flex"></i-tabler>
  </button>

  <!-- --------------------------------------------------------------- -->
  <!-- profile Dropdown -->
  <!-- --------------------------------------------------------------- -->
  <button mat-icon-button [matMenuTriggerFor]="profilemenu">
    <i-tabler name="user"></i-tabler>
  </button>
  <mat-menu #profilemenu="matMenu" class="topbar-dd cardWithShadow">
    <ng-scrollbar class="position-relative" style="height: 50%">
      <div class="p-x-32 p-y-16">
        <h6 class="f-s-16 f-w-600 m-0 mat-subtitle-1">User Profile</h6>

        <div class="d-flex align-items-center p-b-24 b-b-1 m-t-16">
          <i-tabler name="user"></i-tabler>
          <div class="m-l-16">
            <h6 class="f-s-14 f-w-600 m-0 mat-subtitle-1">{{ authService.currentOfficialDetails?.name }}</h6>
            <span class="f-s-14 d-block m-b-4">{{ authService.currentOfficialDetails?.phoneNumber }}</span>
            <span class="d-flex align-items-center">
              <i-tabler name="phone"></i-tabler>
              show phone
            </span>
          </div>
        </div>
      </div>

      <div class="p-y-12 p-x-32">
        <button
          (click)="logout()"
          mat-stroked-button
          color="primary"
          class="w-100"
        >Logout
        </button
        >
      </div>
    </ng-scrollbar>
  </mat-menu>
</mat-toolbar>
