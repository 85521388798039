import { NavItem } from './nav-item/nav-item';

export const navItems: NavItem[] = [
  {
    displayName: 'Recent Grievances',
    iconName: 'clipboard-text',
    route: '/grievances/recent',
  },
  {
    displayName: 'Appointments',
    iconName: 'calendar-time',
    route: '/calendar',
  },
  {
    displayName: 'Most Voted',
    iconName: 'arrow-big-up',
    route: '/grievances/most-voted',
  },
  {
    displayName: 'Archives',
    iconName: 'archive',
    route: '/grievances/archived',
  },
  {
    displayName: 'Account Settings',
    iconName: 'settings',
    route: '/settings',
  },
];
