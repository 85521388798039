import { Component, OnInit } from '@angular/core';
import { injectSpeedInsights } from '@vercel/speed-insights';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
})
export class AppComponent implements OnInit {
  title = 'ination-web';

  ngOnInit() {
    injectSpeedInsights();
  }
}
